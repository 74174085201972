import axios from 'axios'
import { Toast } from 'vant'
import router from '@/router/index.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'application/json'
    }
    // 若接口传入token，则将token设置到请求头
    if (config.token) {
      config.headers['Authorization'] = config.token
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.config.custom || res.code === 0) {
      return res
    } else if ([401, 403].includes(res.code)) {
      Toast.fail(res.message || 'error')
      jumpLogin(response)
      return Promise.reject(response)
    } else {
      Toast.fail(res.message || 'error')
      return Promise.reject(response)
    }
  },
  error => {
    Toast.fail('服务器异常，请稍后重试')
    return Promise.reject(error)
  }
)

const urlMap = {
  shantouOrder: '/shantou/login',
  guangfaBank: '/guangfa/login',
  hbkRightBiz: '/huaxia/login'
}
const jumpLogin = error => {
  const { url } = error.config
  // 遍历 urlMap，找到匹配的路径进行跳转
  for (const [key, path] of Object.entries(urlMap)) {
    if (url.includes(key)) {
      router.replace({ path })
      return
    }
  }
}

export default service
